"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateName = exports.validatePasswordThree = exports.validatePasswordTwo = exports.lengthInUtf8Bytes = exports.validatePassword = void 0;
const validatePassword = (password) => {
    const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$/;
    return re.test(password);
};
exports.validatePassword = validatePassword;
const lengthInUtf8Bytes = (str) => {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    let result = 0;
    str.split('').forEach((item) => {
        const m = encodeURIComponent(item).match(/%[89ABab]/g);
        result = m ? result + m.length : result + 1;
    });
    return result;
};
exports.lengthInUtf8Bytes = lengthInUtf8Bytes;
const validatePasswordTwo = (password) => {
    const re = /(?=.*[@$!%*?&0-9])[A-Za-z\d@$!%*?&0-9]/;
    return re.test(password);
};
exports.validatePasswordTwo = validatePasswordTwo;
const validatePasswordThree = (password) => {
    for (let i = 0; i <= password.length - 3; i++) {
        const s1 = password[i];
        const s2 = password[i + 1];
        const s3 = password[i + 2];
        const s4 = password.charCodeAt(i);
        const s5 = password.charCodeAt(i + 1);
        const s6 = password.charCodeAt(i + 2);
        if (Math.abs(s4 - s5) === 1 && s4 - s5 === s5 - s6) {
            return false;
        }
        if (s1 == s2 && s1 == s3) {
            return false;
        }
    }
    return true;
};
exports.validatePasswordThree = validatePasswordThree;
const validateName = (name) => {
    const re = /^.{8,16}$/;
    return re.test(name);
};
exports.validateName = validateName;
