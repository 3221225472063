"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._abbreviateNumber = exports.formatThousand = exports.formatMoneyUSD = exports.formatMoney = void 0;
var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
const formatMoney = (price) => {
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 4 }).format(price);
};
exports.formatMoney = formatMoney;
const formatMoneyUSD = (price) => {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 3 }).format(price);
};
exports.formatMoneyUSD = formatMoneyUSD;
const formatThousand = (num) => {
    const n = String(num), p = n.indexOf('.');
    return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => p < 0 || i < p ? `${m},` : m);
};
exports.formatThousand = formatThousand;
const _abbreviateNumber = (number) => {
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;
    // if zero, we don't need a suffix
    if (tier == 0)
        return Math.floor(number * 10) / 10;
    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = number / scale;
    // format number and add suffix
    return Math.floor(scaled * 10) / 10 + suffix;
};
exports._abbreviateNumber = _abbreviateNumber;
