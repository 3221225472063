"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._hasIOSPermission = exports._hasAndroidPermission = exports._hasIOSPermissionCamera = exports._hasAndroidPermissionCamera = exports._hasIOSPermissionWriteExternalStorage = exports._hasAndroidPermissionWriteExternalStorage = void 0;
const react_native_1 = require("react-native");
const react_native_permissions_1 = require("react-native-permissions");
const _hasAndroidPermissionWriteExternalStorage = async () => {
    const permission = react_native_1.PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE;
    const hasPermission = await react_native_1.PermissionsAndroid.check(permission);
    if (hasPermission) {
        return true;
    }
    const status = await react_native_1.PermissionsAndroid.request(permission);
    return status === 'granted';
};
exports._hasAndroidPermissionWriteExternalStorage = _hasAndroidPermissionWriteExternalStorage;
const _hasIOSPermissionWriteExternalStorage = async () => {
    const permission = react_native_permissions_1.PERMISSIONS.IOS.PHOTO_LIBRARY;
    const hasPermission = await (0, react_native_permissions_1.check)(permission);
    if (hasPermission == 'granted') {
        return true;
    }
    const status = await (0, react_native_permissions_1.request)(permission, { title: 'hung', message: 'hung', buttonPositive: 'hung' });
    if (status === 'blocked') {
        react_native_1.Linking.openURL('app-settings://');
    }
    return status === 'granted';
};
exports._hasIOSPermissionWriteExternalStorage = _hasIOSPermissionWriteExternalStorage;
const _hasAndroidPermissionCamera = async () => {
    const permission = react_native_1.PermissionsAndroid.PERMISSIONS.CAMERA;
    const hasPermission = await react_native_1.PermissionsAndroid.check(permission);
    if (hasPermission) {
        return true;
    }
    const status = await react_native_1.PermissionsAndroid.request(permission);
    return status === 'granted';
};
exports._hasAndroidPermissionCamera = _hasAndroidPermissionCamera;
const _hasIOSPermissionCamera = async () => {
    const permission = react_native_permissions_1.PERMISSIONS.IOS.CAMERA;
    const hasPermission = await (0, react_native_permissions_1.check)(permission);
    if (hasPermission == 'granted') {
        return true;
    }
    const status = await (0, react_native_permissions_1.request)(permission);
    if (status === 'blocked') {
        react_native_1.Linking.openURL('app-settings://');
    }
    return status === 'granted';
};
exports._hasIOSPermissionCamera = _hasIOSPermissionCamera;
const _hasAndroidPermission = async (permissionText) => {
    const permission = react_native_1.PermissionsAndroid.PERMISSIONS[permissionText];
    const hasPermission = await react_native_1.PermissionsAndroid.check(permission);
    if (hasPermission) {
        return true;
    }
    const status = await react_native_1.PermissionsAndroid.request(permission);
    return status === 'granted';
};
exports._hasAndroidPermission = _hasAndroidPermission;
const _hasIOSPermission = async (permissionText) => {
    const permission = permissionText;
    const hasPermission = await (0, react_native_permissions_1.check)(permission);
    if (hasPermission == 'granted') {
        return true;
    }
    const status = await (0, react_native_permissions_1.request)(permission);
    if (status === 'blocked') {
        react_native_1.Linking.openURL('app-settings://');
    }
    return status === 'granted';
};
exports._hasIOSPermission = _hasIOSPermission;
