"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shopModel = void 0;
const shop_service_1 = require("../../../app/service/api/shop.service");
const easy_peasy_1 = require("easy-peasy");
exports.shopModel = {
    coin: false,
    setCoin: (0, easy_peasy_1.action)((state, payload) => {
        state.coin = payload;
    }),
    listCoinMerge: [],
    setListCoinMerge: (0, easy_peasy_1.action)((state, payload) => {
        state.listCoinMerge = payload;
    }),
    isContinueVideo: false,
    setIsContinueVideo: (0, easy_peasy_1.action)((state, payload) => {
        state.isContinueVideo = payload;
    }),
    listShop: [],
    setListShop: (0, easy_peasy_1.action)((state, payload) => {
        state.listShop = payload;
    }),
    isListShop: false,
    setIsListShop: (0, easy_peasy_1.action)((state, payload) => {
        state.isListShop = payload;
    }),
    getListShop: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.listShop)(payload).then(async (res) => {
            actions.setListShop(res.data?.data?.data);
            actions.setIsListShop(false);
            return res.data;
        }).catch(error => {
            actions.setIsListShop(true);
        });
    }),
    listInventory: [],
    setListInventory: (0, easy_peasy_1.action)((state, payload) => {
        state.listInventory = payload;
    }),
    isProductInventory: false,
    setIsProductInventory: (0, easy_peasy_1.action)((state, payload) => {
        state.isProductInventory = payload;
    }),
    productInventory: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.productInventory)(payload).then(async (res) => {
            actions.setListInventory(res.data?.data.data);
            actions.setIsProductInventory(true);
            return res.data;
        }).catch(error => {
            actions.setIsProductInventory(false);
        });
    }),
    isBuyProduct: false,
    setIsBuyProduct: (0, easy_peasy_1.action)((state, payload) => {
        state.isBuyProduct = payload;
    }),
    buyProduct: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.buyProduct)(payload).then(async (res) => {
            actions.setIsBuyProduct(true);
            return res.data;
        }).catch(error => {
            actions.setIsBuyProduct(false);
        });
    }),
    priceUpgrade: false,
    setPriceUpgrade: (0, easy_peasy_1.action)((state, payload) => {
        state.priceUpgrade = payload;
    }),
    isPriceUpgrade: false,
    setIsPriceUpgrade: (0, easy_peasy_1.action)((state, payload) => {
        state.isPriceUpgrade = payload;
    }),
    getPriceUpgrade: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.priceUpgrade)(payload).then(async (res) => {
            actions.setPriceUpgrade(res.data?.data);
            actions.setIsPriceUpgrade(true);
            return res.data;
        }).catch(error => {
            actions.setIsPriceUpgrade(false);
        });
    }),
    isProductUpgrade: false,
    setIsProductUpgrade: (0, easy_peasy_1.action)((state, payload) => {
        state.isProductUpgrade = payload;
    }),
    productUpgrade: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.productUpgrade)(payload).then(async (res) => {
            actions.setIsProductUpgrade(true);
            return res.data;
        }).catch(error => {
            actions.setIsPriceUpgrade(false);
        });
    }),
    repairFees: false,
    setRepairFees: (0, easy_peasy_1.action)((state, payload) => {
        state.repairFees = payload;
    }),
    isRepairFees: false,
    setIsRepairFees: (0, easy_peasy_1.action)((state, payload) => {
        state.isRepairFees = payload;
    }),
    getRepairFees: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.repairFees)(payload).then(async (res) => {
            actions.setRepairFees(res.data?.data?.repair_fees);
            actions.setIsRepairFees(true);
            return res.data;
        }).catch(error => {
            actions.setIsPriceUpgrade(false);
        });
    }),
    isRepair: false,
    setIsRepair: (0, easy_peasy_1.action)((state, payload) => {
        state.isRepair = payload;
    }),
    repair: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.repair)(payload).then(async (res) => {
            actions.setIsRepair(true);
            return res.data;
        }).catch(error => {
            actions.setIsPriceUpgrade(false);
        });
    }),
    isGiveProduct: false,
    setIsGiveProduct: (0, easy_peasy_1.action)((state, payload) => {
        state.isGiveProduct = payload;
    }),
    giveProduct: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.giveProduct)(payload).then(async (res) => {
            if (res.data?.data?.status != 1) {
                actions.setIsGiveProduct(true);
            }
            return res.data;
        }).catch(error => {
            actions.setIsGiveProduct(false);
        });
    }),
    isProductChoose: false,
    setIsProductChoose: (0, easy_peasy_1.action)((state, payload) => {
        state.isProductChoose = payload;
    }),
    productChoose: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.productInventory)(payload).then(async (res) => {
            actions.setIsProductChoose(true);
            return res.data;
        }).catch(error => {
            actions.setIsProductChoose(false);
        });
    }),
    chooseUserStore: false,
    setChooseUserStore: (0, easy_peasy_1.action)((state, payload) => {
        state.chooseUserStore = payload;
    }),
    chooseProductStore: [],
    setChooseProductStore: (0, easy_peasy_1.action)((state, payload) => {
        state.chooseProductStore = payload;
    }),
    isGiveManyProduct: false,
    setIsGiveManyProduct: (0, easy_peasy_1.action)((state, payload) => {
        state.isGiveManyProduct = payload;
    }),
    giveManyProduct: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, shop_service_1.giveManyProduct)(payload).then(async (res) => {
            if (res.data?.data?.status != 1) {
                actions.setIsGiveManyProduct(true);
            }
            return res.data;
        }).catch(error => {
            actions.setIsGiveManyProduct(false);
        });
    }),
};
