"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingActionSelector = exports.settingStateSelector = exports.coinActionSelector = exports.coinStateSelector = exports.noticeActionSelector = exports.noticeStateSelector = exports.shopActionSelector = exports.shopStateSelector = exports.modalActionSelector = exports.modalStateSelector = exports.authActionSelector = exports.authStateSelector = exports.useStore = exports.useStoreDispatch = exports.useStoreState = exports.useStoreActions = void 0;
const easy_peasy_1 = require("easy-peasy");
const auth_1 = require("./model/auth");
const modal_1 = require("./model/modal");
const shop_1 = require("./model/shop");
const notice_1 = require("./model/notice");
const coin_1 = require("./model/coin");
const setting_1 = require("./model/setting");
const storeModel = { auth: auth_1.authModel, modal: modal_1.modalModel, shop: shop_1.shopModel, notice: notice_1.noticeModel, coin: coin_1.coinModel, setting: setting_1.settingModel };
_a = (0, easy_peasy_1.createTypedHooks)(), exports.useStoreActions = _a.useStoreActions, exports.useStoreState = _a.useStoreState, exports.useStoreDispatch = _a.useStoreDispatch, exports.useStore = _a.useStore;
// Auth
const authStateSelector = (state) => state.auth;
exports.authStateSelector = authStateSelector;
const authActionSelector = (state) => state.auth;
exports.authActionSelector = authActionSelector;
// Modal
const modalStateSelector = (state) => state.modal;
exports.modalStateSelector = modalStateSelector;
const modalActionSelector = (state) => state.modal;
exports.modalActionSelector = modalActionSelector;
// Shop
const shopStateSelector = (state) => state.shop;
exports.shopStateSelector = shopStateSelector;
const shopActionSelector = (state) => state.shop;
exports.shopActionSelector = shopActionSelector;
// Notice
const noticeStateSelector = (state) => state.notice;
exports.noticeStateSelector = noticeStateSelector;
const noticeActionSelector = (state) => state.notice;
exports.noticeActionSelector = noticeActionSelector;
// Coin
const coinStateSelector = (state) => state.coin;
exports.coinStateSelector = coinStateSelector;
const coinActionSelector = (state) => state.coin;
exports.coinActionSelector = coinActionSelector;
// Setting
const settingStateSelector = (state) => state.setting;
exports.settingStateSelector = settingStateSelector;
const settingActionSelector = (state) => state.setting;
exports.settingActionSelector = settingActionSelector;
const store = (0, easy_peasy_1.createStore)(storeModel, {
    name: "store",
    // middleware,
});
exports.default = store;
