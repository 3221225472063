"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noticeModel = void 0;
const notification_service_1 = require("../../../app/service/api/notification.service");
const easy_peasy_1 = require("easy-peasy");
exports.noticeModel = {
    isRedirectNotice: false,
    setIsRedirectNotice: (0, easy_peasy_1.action)((state, payload) => {
        state.isRedirectNotice = payload;
    }),
    listNotice: [],
    setListNotice: (0, easy_peasy_1.action)((state, payload) => {
        state.listNotice = payload;
    }),
    isListNotice: false,
    setIsListNotice: (0, easy_peasy_1.action)((state, payload) => {
        state.isListNotice = payload;
    }),
    getListNotice: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, notification_service_1.listNotice)(payload).then(async (res) => {
            actions.setListNotice(res.data?.data?.data);
            actions.setIsListNotice(true);
            return res.data;
        }).catch(error => {
            actions.setIsListNotice(false);
        });
    }),
    listQAndA: [],
    setListQAndA: (0, easy_peasy_1.action)((state, payload) => {
        state.listQAndA = payload;
    }),
    isListQAndA: false,
    setIsListQAndA: (0, easy_peasy_1.action)((state, payload) => {
        state.isListQAndA = payload;
    }),
    getListQAndA: (0, easy_peasy_1.thunk)(async (actions, payload) => {
        return (0, notification_service_1.listQAndA)(payload).then(async (res) => {
            actions.setListQAndA(res.data?.data?.data);
            actions.setIsListQAndA(true);
            return res.data;
        }).catch(error => {
            actions.setIsListNotice(false);
        });
    }),
};
