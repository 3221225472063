"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncStorageTransform = void 0;
const easy_peasy_1 = require("easy-peasy");
const lodash_1 = require("lodash");
exports.AsyncStorageTransform = (0, easy_peasy_1.createTransform)((inboundState) => {
    return (0, lodash_1.isEmpty)(inboundState) ? "" : JSON.stringify(inboundState);
}, (outboundState) => {
    return (0, lodash_1.isEmpty)(outboundState) ? {} : JSON.parse(outboundState);
});
