"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalModel = void 0;
const easy_peasy_1 = require("easy-peasy");
exports.modalModel = {
    connectivityModal: false,
    setConnectivityModal: (0, easy_peasy_1.action)((state, payload) => {
        state.connectivityModal = payload;
    }),
    onlineOfflineModal: false,
    setOnlineOfflineModal: (0, easy_peasy_1.action)((state, payload) => {
        state.onlineOfflineModal = payload;
    }),
    isResetPasswordSuccessVisible: false,
    setIsResetPasswordSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isResetPasswordSuccessVisible = payload;
    }),
    isTermsVisible: false,
    setIsTermsVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isTermsVisible = payload;
    }),
    isTypeTerms: 1,
    setIsTypeTerms: (0, easy_peasy_1.action)((state, payload) => {
        state.isTypeTerms = payload;
    }),
    isNotificationVisible: false,
    setIsNotificationVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isNotificationVisible = payload;
    }),
    isLogoutVisible: false,
    setIsLogoutVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isLogoutVisible = payload;
    }),
    isEditAccountSuccessVisible: false,
    setIsEditAccountSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isEditAccountSuccessVisible = payload;
    }),
    isModalSuccessSwapVisible: false,
    setIsModalSuccessSwapVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isModalSuccessSwapVisible = payload;
    }),
    isPasswordErrorVisible: false,
    setIsPasswordErrorVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isPasswordErrorVisible = payload;
    }),
    isChangePasswordSuccessVisible: false,
    setIsChangePasswordSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isChangePasswordSuccessVisible = payload;
    }),
    isBuyVisible: false,
    setIsBuyVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isBuyVisible = payload;
    }),
    isUpgradeVisible: false,
    setIsUpgradeVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isUpgradeVisible = payload;
    }),
    isUpgradeSuccessVisible: false,
    setIsUpgradeSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isUpgradeSuccessVisible = payload;
    }),
    isInformationItemVisible: false,
    setIsInformationItemVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isInformationItemVisible = payload;
    }),
    isReliabilityFeeVisible: false,
    setIsReliabilityFeeVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isReliabilityFeeVisible = payload;
    }),
    isReliabilityFeeSuccessVisible: false,
    setIsReliabilityFeeSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isReliabilityFeeSuccessVisible = payload;
    }),
    isMergeItemsVisible: false,
    setIsMergeItemsVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isMergeItemsVisible = payload;
    }),
    isStopVideoVisible: false,
    setIsStopVideoVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isStopVideoVisible = payload;
    }),
    textNotification: '',
    setTextNotification: (0, easy_peasy_1.action)((state, payload) => {
        state.textNotification = payload;
    }),
    isCoinVisible: false,
    setIsCoinVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isCoinVisible = payload;
    }),
    isSettingVisible: false,
    setIsSettingVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isSettingVisible = payload;
    }),
    isGuideVisible: false,
    setIsGuideVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isGuideVisible = payload;
    }),
    isPaymentVisible: false,
    setIsPaymentVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isPaymentVisible = payload;
    }),
    isSendItemToUserVisible: false,
    setIsSendItemToUserVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isSendItemToUserVisible = payload;
    }),
    isSendItemToUserSuccessVisible: false,
    setIsSendItemToUserSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isSendItemToUserSuccessVisible = payload;
    }),
    isLoadingVisible: true,
    setIsLoadingVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isLoadingVisible = payload;
    }),
    isBuyErrorVisible: false,
    setIsBuyErrorVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isBuyErrorVisible = payload;
    }),
    isWithdrawalSuccessVisible: false,
    setIsWithdrawalSuccessVisible: (0, easy_peasy_1.action)((state, payload) => {
        state.isWithdrawalSuccessVisible = payload;
    }),
    isShowSearchUser: false,
    setIsShowSearchUser: (0, easy_peasy_1.action)((state, payload) => {
        state.isShowSearchUser = payload;
    }),
    isChooseProduct: false,
    setIsChooseProduct: (0, easy_peasy_1.action)((state, payload) => {
        state.isChooseProduct = payload;
    }),
};
