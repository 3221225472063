"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customMapStyle = void 0;
const react_native_1 = require("react-native");
const rn_responsive_styles_1 = require("rn-responsive-styles");
const theme_config_1 = require("./theme-config");
const DEFAULT_SPACING = 16;
exports.default = (0, rn_responsive_styles_1.CreateResponsiveStyle)({
    container: {
        paddingHorizontal: 100,
        width: '100%',
    },
    containerContent: {
        minHeight: react_native_1.Dimensions.get('window').height - 66,
        backgroundColor: theme_config_1.PaperThemeDefault.colors.white,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    defaultMargin: {
        margin: DEFAULT_SPACING,
    },
    defaultPadding: {
        padding: DEFAULT_SPACING,
    },
    center: {
        justifyContent: "center",
        alignItems: "center",
    },
    selfCenter: {
        alignSelf: "center",
    },
    transparent: {
        backgroundColor: "transparent",
    },
    spaceBetween: {
        justifyContent: "space-between",
    },
    textUnderline: {
        textDecorationLine: 'underline',
    },
    sidebar: {
        flex: 1,
        minHeight: react_native_1.Dimensions.get('window').height,
    },
    body: {
        flex: 3,
        padding: 80,
    },
    input: {
        fontSize: 12,
        color: '#242424',
        borderColor: '#000',
        borderRadius: 10,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        paddingVertical: react_native_1.Platform.OS == 'ios' ? 10.5 : 5.5,
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        lineHeight: 17,
    },
    buttonPrimaryLG: {
        fontSize: 18,
        fontWeight: '700',
        marginVertical: 14,
        marginHorizontal: 20,
        lineHeight: 26,
    },
    buttonPrimaryMD: {
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 24,
        marginVertical: 8,
        marginHorizontal: 10,
    },
    buttonPrimarySM: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
        marginVertical: 8,
        marginHorizontal: 12,
    },
    h1Light: {
        fontSize: 48,
        fontWeight: '300',
        lineHeight: 60,
    },
    h1Bold: {
        fontSize: 48,
        fontWeight: '700',
        lineHeight: 60,
    },
    h2Light: {
        fontSize: 38,
        fontWeight: '300',
        lineHeight: 48,
    },
    h2Bold: {
        fontSize: 38,
        fontWeight: '700',
        lineHeight: 48,
    },
    h3Light: {
        fontSize: 30,
        fontWeight: '300',
        lineHeight: 40,
    },
    h3Bold: {
        fontSize: 30,
        fontWeight: '700',
        lineHeight: 40,
    },
    h4Light: {
        fontSize: 24,
        fontWeight: '300',
        lineHeight: 32,
    },
    h4Bold: {
        fontSize: 24,
        fontWeight: '700',
        lineHeight: 32,
    },
    h5Light: {
        fontSize: 20,
        fontWeight: '300',
        lineHeight: 28,
    },
    h5Bold: {
        fontSize: 20,
        fontWeight: '700',
        lineHeight: 28,
    },
    bodyLGRegular: {
        fontSize: 18,
        fontWeight: '400',
        lineHeight: 26,
    },
    bodyLGBold: {
        fontSize: 18,
        fontWeight: '700',
        lineHeight: 26,
    },
    bodyMDRegular: {
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 24,
    },
    bodyMDBold: {
        fontSize: 16,
        fontWeight: '700',
        lineHeight: 24,
    },
    bodySMRegular: {
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 22,
    },
    bodySMSemibold: {
        fontSize: 14,
        fontWeight: '600',
        lineHeight: 22,
    },
    bodySMBold: {
        fontSize: 14,
        fontWeight: '700',
        lineHeight: 22,
    },
    bodyXSRegular: {
        fontSize: 12,
        fontWeight: '400',
        lineHeight: 18,
    },
    bodyMDSemibold: {
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 24,
    },
    bodyXSBold: {
        fontSize: 12,
        fontWeight: '700',
        lineHeight: 18,
    },
    bodyXXSRegular: {
        fontSize: 10,
        fontWeight: '400',
        lineHeight: 12,
    },
    bodyXXSBold: {
        fontSize: 10,
        fontWeight: '700',
        lineHeight: 12,
    },
    drawerStyle: {
        shadowOffset: {
            width: 2,
            height: -20,
        },
        shadowOpacity: 0.08,
        shadowRadius: 30,
        elevation: 30,
    },
    shadow: {
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    stokeTop: {
        borderTopColor: theme_config_1.PaperThemeDefault.colors.border,
        borderTopWidth: 1,
    },
    stokeBottom: {
        borderBottomColor: theme_config_1.PaperThemeDefault.colors.border,
        borderBottomWidth: 1,
    },
    stokeLeft: {
        borderLeftColor: theme_config_1.PaperThemeDefault.colors.border,
        borderLeftWidth: 1,
    },
    stokeLeftBlack: {
        borderLeftColor: theme_config_1.PaperThemeDefault.colors.black,
        borderLeftWidth: 1,
    },
    stoke: {
        borderColor: theme_config_1.PaperThemeDefault.colors.border,
        borderWidth: 1,
    },
    stokeBlack: {
        borderColor: theme_config_1.PaperThemeDefault.colors.black,
        borderWidth: 1,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme_config_1.PaperThemeDefault.colors.background_layout,
    },
    rightView: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundColor: theme_config_1.PaperThemeDefault.colors.background_layout,
    },
    modalView: {
        backgroundColor: theme_config_1.PaperThemeDefault.colors.white,
        paddingVertical: 40,
        paddingHorizontal: 20,
        borderWidth: 1,
        borderColor: theme_config_1.PaperThemeDefault.colors.black,
        alignItems: 'center',
        shadowColor: theme_config_1.PaperThemeDefault.colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalViewRight: {
        flex: 1,
        width: '60%',
        overflow: 'scroll',
        backgroundColor: theme_config_1.PaperThemeDefault.colors.white,
        paddingVertical: 40,
        paddingHorizontal: 64,
        borderWidth: 1,
        borderColor: theme_config_1.PaperThemeDefault.colors.black,
        alignItems: 'center',
        shadowColor: theme_config_1.PaperThemeDefault.colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    textLGSemibold: {
        fontSize: 18,
        fontWeight: '600',
        lineHeight: 26,
    },
}, {
    [rn_responsive_styles_1.DEVICE_SIZES.XS]: {
        container: {
            paddingHorizontal: 16,
        },
        sidebar: {
            display: 'none',
        },
        h1Light: {
            fontSize: 36,
            fontWeight: '300',
            lineHeight: 48,
        },
        h1Bold: {
            fontSize: 36,
            fontWeight: '700',
            lineHeight: 48,
        },
        h2Light: {
            fontSize: 28,
            fontWeight: '300',
            lineHeight: 36,
        },
        h2Bold: {
            fontSize: 28,
            fontWeight: '700',
            lineHeight: 38,
        },
        h3Light: {
            fontSize: 24,
            fontWeight: '300',
            lineHeight: 32,
        },
        h3Bold: {
            fontSize: 24,
            fontWeight: '700',
            lineHeight: 32,
        },
        h4Light: {
            fontSize: 20,
            fontWeight: '300',
            lineHeight: 28,
        },
        h4Bold: {
            fontSize: 20,
            fontWeight: '700',
            lineHeight: 28,
        },
        h5Light: {
            fontSize: 18,
            fontWeight: '300',
            lineHeight: 28,
        },
        h5Bold: {
            fontSize: 18,
            fontWeight: '700',
            lineHeight: 28,
        },
        bodySMBold: {
            fontSize: 14,
            fontWeight: '600',
            lineHeight: 22,
        },
        textLGSemibold: {
            fontSize: 14,
            fontWeight: '600',
            lineHeight: 20,
        },
    },
    [rn_responsive_styles_1.DEVICE_SIZES.SM]: {
        container: {
            paddingHorizontal: 16,
        },
        sidebar: {
            display: 'none',
        },
        h1Light: {
            fontSize: 36,
            fontWeight: '300',
            lineHeight: 48,
        },
        h1Bold: {
            fontSize: 36,
            fontWeight: '700',
            lineHeight: 48,
        },
        h2Light: {
            fontSize: 28,
            fontWeight: '300',
            lineHeight: 36,
        },
        h2Bold: {
            fontSize: 38,
            fontWeight: '700',
            lineHeight: 48,
        },
        h3Light: {
            fontSize: 24,
            fontWeight: '300',
            lineHeight: 32,
        },
        h3Bold: {
            fontSize: 24,
            fontWeight: '700',
            lineHeight: 32,
        },
        h4Light: {
            fontSize: 20,
            fontWeight: '300',
            lineHeight: 28,
        },
        h4Bold: {
            fontSize: 20,
            fontWeight: '700',
            lineHeight: 28,
        },
        h5Light: {
            fontSize: 18,
            fontWeight: '300',
            lineHeight: 28,
        },
        h5Bold: {
            fontSize: 20,
            fontWeight: '700',
            lineHeight: 28,
        },
        bodySMBold: {
            fontSize: 14,
            fontWeight: '600',
            lineHeight: 22,
        },
        textLGSemibold: {
            fontSize: 14,
            fontWeight: '600',
            lineHeight: 20,
        },
    },
    [rn_responsive_styles_1.DEVICE_SIZES.MD]: {
        container: {
            paddingHorizontal: 16,
        },
        sidebar: {
            display: 'none',
        },
    },
    [rn_responsive_styles_1.DEVICE_SIZES.LG]: {},
    [rn_responsive_styles_1.DEVICE_SIZES.XL]: {},
});
exports.customMapStyle = [
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
];
