"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customMapStyle = void 0;
const react_native_1 = require("react-native");
const theme_config_1 = require("./config/theme-config");
const DEFAULT_SPACING = 16;
exports.default = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    defaultMargin: {
        margin: DEFAULT_SPACING,
    },
    defaultPadding: {
        padding: DEFAULT_SPACING,
    },
    center: {
        justifyContent: "center",
        alignItems: "center",
    },
    selfCenter: {
        alignSelf: "center",
    },
    transparent: {
        backgroundColor: "transparent",
    },
    spaceBetween: {
        justifyContent: "space-between",
    },
    boxImage: {
        borderColor: '#000',
        borderRadius: 12,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        backgroundColor: '#fff',
    },
    input: {
        fontSize: 12,
        color: '#242424',
        borderColor: '#000',
        borderRadius: 10,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        paddingVertical: react_native_1.Platform.OS == 'ios' ? 10.5 : 5.5,
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        lineHeight: 17,
        height: 40,
    },
    button: {
        borderColor: '#414141',
        borderRadius: 20,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
    },
    labelButton: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000',
        letterSpacing: 0.5,
        lineHeight: 17,
    },
    labelButtonPrimary: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000',
        letterSpacing: 0.5,
        lineHeight: 17,
        paddingTop: 1,
    },
    text10ExtraBold: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 12,
    },
    text14SemiBold: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    text12Regular: {
        fontSize: 12,
        fontWeight: '400',
        color: '#242424',
        lineHeight: 17,
    },
    text9Bold: {
        fontSize: 9,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 11,
    },
    text9Regular: {
        fontSize: 9,
        fontWeight: '400',
        color: '#242424',
        lineHeight: 11,
    },
    text12Medium: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 15,
    },
    text16Bold: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    text16Regular: {
        fontSize: 16,
        fontWeight: 'normal',
        color: '#242424',
        lineHeight: 19,
    },
    text16Medium: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 20,
    },
    text16ExtraBold: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 19,
    },
    text20Regular: {
        fontSize: 20,
        fontWeight: '400',
        color: '#242424',
        lineHeight: 17,
    },
    text20Bold: {
        fontSize: 20,
        fontWeight: '700',
        color: '#242424',
        lineHeight: 24,
    },
    text12SemiBold: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 15,
    },
    text12Bold: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    textTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    textList: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    textUnderline: {
        fontSize: 14,
        textDecorationLine: 'underline',
        lineHeight: 17,
    },
    text14Regular: {
        fontSize: 14,
        fontWeight: '400',
        color: '#242424',
        lineHeight: 17,
    },
    text14Medium: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#242424',
        lineHeight: 17,
    },
    text14Semi500: {
        fontSize: 14,
        fontWeight: '500',
        color: '#242424',
        lineHeight: 17,
    },
    bottomSheetContainer: {
        ...react_native_1.StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        zIndex: 999,
        elevation: 999,
    },
    animationContainer: {
        flex: 1,
        flexDirection: 'column-reverse',
        height: '100%',
    },
    bottomSheet: {
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        backgroundColor: '#fff',
    },
    touchableTransparentContainer: {
        flex: 1,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        fontStyle: 'italic',
        textAlign: "center",
        textTransform: 'uppercase',
    },
    boxLogin: {
        borderColor: '#000',
        borderRadius: 30,
        borderTopWidth: 2,
        borderLeftWidth: 2,
        borderRightWidth: 4,
        borderBottomWidth: 5,
    },
    inputDisable: {
        backgroundColor: "#E0F2FF",
    },
    header: {
        borderColor: '#000',
        borderRadius: 10,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        paddingHorizontal: 11,
        paddingVertical: 6,
        backgroundColor: '#fff',
    },
    headerText: {},
    content: {
        borderColor: '#000',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopWidth: 0,
        borderLeftWidth: 1,
        borderRightWidth: 4,
        borderBottomWidth: 5,
        paddingHorizontal: 11,
        paddingVertical: 6,
        backgroundColor: '#fff',
        marginBottom: 10,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        backgroundColor: theme_config_1.PaperThemeDefault.colors.white,
        width: 343,
        height: 287.29,
        borderRadius: 20,
        borderTopWidth: 2,
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderBottomWidth: 2,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
});
exports.customMapStyle = [
    {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
];
