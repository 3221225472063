"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingModel = void 0;
const easy_peasy_1 = require("easy-peasy");
exports.settingModel = {
    language: 'kr',
    setLanguage: (0, easy_peasy_1.action)((state, payload) => {
        state.language = payload;
    }),
    notification: false,
    setNotification: (0, easy_peasy_1.action)((state, payload) => {
        state.notification = payload;
    }),
    isCheckGoBack: false,
    setIsCheckGoBack: (0, easy_peasy_1.action)((state, payload) => {
        state.isCheckGoBack = payload;
    }),
};
