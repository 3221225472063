"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CombinedDarkTheme = exports.CombinedDefaultTheme = exports.PaperThemeDark = exports.PaperThemeDefault = void 0;
const react_native_paper_1 = require("react-native-paper");
const native_1 = require("@react-navigation/native");
const LIGHT_COLOR = {
    PURPLE_700: "#000",
    PURPLE_600: "#410A9A",
    PURPLE_100: "#FBF7FD",
    BLUE_600: "#55ACEE",
    BLUE_200: "#E7EAFD",
    BLUE_100: "#F6F7FE",
    BLACK_900: "#182135",
    GRAY_700: "#595959",
    GRAY_600: "#8E8E8E",
    GRAY_100: "#F7F7F7",
    GRAY_400: "#B2B2B2",
    GRAY_300: "#707070",
    GRAY_500: '#CDCDCD',
    GRAY_FF57: "#FFF5F7",
    GRAY_444: "#C4C4C4",
    WHITE: "#FFF",
    BACKDROP: "#18213599",
    TEXTINPUT_BG: "#EDEDED",
    KAKAOTALK: "#FEE500",
    FACEBOOK: "#1877F2",
    BLUE_20: "#2196F3",
    TRANSPARENT: "transparent",
    BACKGROUND_LOGIN: '#CFC6E2',
    BLACK: '#000',
    PLACE_HOLDER: '#CFCFCF',
    ORANGE: '#F06B1A',
    VIOLET_LIGHT: '#E7D9FD',
    VIOLET_BA: '#9690BA',
    GRAY: '#F5F5F5',
    TEXT_CONTENT: '#242424',
    TEXT_CONTENT_GRAY: '#0F0F0F',
    TAB_GRAY: '#828282',
    LAYOUT: 'rgba(0, 0, 0, 0.3)',
    RGB_BLACK_LIGHT: 'rgba(0, 0, 0, 0.52)',
    BLUE: '#1977F3',
    SPLASH: '#FFF',
    GREEN: '#1FCFC1',
    RED: '#F52929',
    PINK: '#FE7991',
    PINK_LIGHT: '#FFCAD4',
    YELLOW: '#EFC75E',
    BORDER_GRAY: '#BFBFBF',
    ORANGE_LV_MAX: '#FF7E4B',
    BACKGROUND_LAYOUT: 'rgba(152, 152, 152, 0.95)',
};
const DARK_COLOR = {
    PURPLE_700: "#000",
    PURPLE_600: "#410A9A",
    PURPLE_100: "#FBF7FD",
    BLUE_600: "#55ACEE",
    BLUE_200: "#E7EAFD",
    BLUE_100: "#F6F7FE",
    BLACK_900: "#182135",
    GRAY_700: "#595959",
    GRAY_600: "#8E8E8E",
    GRAY_500: '#CDCDCD',
    GRAY_100: "#F7F7F7",
    WHITE: "#FFF",
    GRAY_400: "#B2B2B2",
    GRAY_300: "#707070",
    GRAY_FF57: "#FFF5F7",
    GRAY_444: "#C4C4C4",
    BACKDROP: "#18213599",
    TEXTINPUT_BG: "#EDEDED",
    KAKAOTALK: "#FEE500",
    FACEBOOK: "#1877F2",
    BLUE_20: "#2196F3",
    TRANSPARENT: "transparent",
    BACKGROUND_LOGIN: '#CFC6E2',
    BLACK: '#000',
    PLACE_HOLDER: '#CFCFCF',
    ORANGE: '#F06B1A',
    VIOLET_LIGHT: '#E7D9FD',
    VIOLET_BA: '#9690BA',
    GRAY: '#D2D2D2',
    TEXT_CONTENT: '#242424',
    TEXT_CONTENT_GRAY: '#0F0F0F',
    TAB_GRAY: '#828282',
    LAYOUT: 'rgba(0, 0, 0, 0.3)',
    RGB_BLACK_LIGHT: 'rgba(0, 0, 0, 0.5)',
    BLUE: '#1977F3',
    SPLASH: '#FFF',
    GREEN: '#1FCFC1',
    RED: '#F52929',
    PINK: '#FE7991',
    BACKGROUND_LAYOUT: 'rgba(152, 152, 152, 0.95)',
    PINK_LIGHT: '#FFCAD4',
    YELLOW: '#EFC75E',
    BORDER_GRAY: '#BFBFBF',
    ORANGE_LV_MAX: '#FF7E4B',
};
exports.PaperThemeDefault = {
    ...react_native_paper_1.DefaultTheme,
    colors: {
        ...react_native_paper_1.DefaultTheme.colors,
        ...native_1.DefaultTheme.colors,
        primary: LIGHT_COLOR.PURPLE_700,
        accent: LIGHT_COLOR.BLUE_600,
        shadow: LIGHT_COLOR.GRAY_600,
        text: LIGHT_COLOR.TEXT_CONTENT_GRAY,
        background: LIGHT_COLOR.WHITE,
        white: LIGHT_COLOR.WHITE,
        purple_600: LIGHT_COLOR.PURPLE_600,
        purple_100: LIGHT_COLOR.PURPLE_100,
        gray_400: LIGHT_COLOR.GRAY_400,
        gray_500: LIGHT_COLOR.GRAY_500,
        gray_700: LIGHT_COLOR.GRAY_700,
        gray_100: LIGHT_COLOR.GRAY_100,
        blue_200: LIGHT_COLOR.BLUE_200,
        blue_100: LIGHT_COLOR.BLUE_100,
        backdrop: LIGHT_COLOR.BACKDROP,
        textinput_bg: LIGHT_COLOR.TEXTINPUT_BG,
        kakaotalk: LIGHT_COLOR.KAKAOTALK,
        facebook: LIGHT_COLOR.FACEBOOK,
        blue_20: LIGHT_COLOR.BLUE_20,
        transparent: LIGHT_COLOR.TRANSPARENT,
        background_login: LIGHT_COLOR.BACKGROUND_LOGIN,
        black: LIGHT_COLOR.BLACK,
        placeholder: LIGHT_COLOR.PLACE_HOLDER,
        orange: LIGHT_COLOR.ORANGE,
        violet_light: LIGHT_COLOR.VIOLET_LIGHT,
        violet_ba: LIGHT_COLOR.VIOLET_BA,
        gray: LIGHT_COLOR.GRAY,
        text_content: LIGHT_COLOR.TEXT_CONTENT,
        text_content_gray: LIGHT_COLOR.TEXT_CONTENT_GRAY,
        tab_gray: LIGHT_COLOR.TAB_GRAY,
        layout: LIGHT_COLOR.LAYOUT,
        background_layout: LIGHT_COLOR.BACKGROUND_LAYOUT,
        gray_300: LIGHT_COLOR.GRAY_300,
        gray_ff57: LIGHT_COLOR.GRAY_FF57,
        gray_444: LIGHT_COLOR.GRAY_444,
        rgb_black_light: LIGHT_COLOR.RGB_BLACK_LIGHT,
        blue: LIGHT_COLOR.BLUE,
        splash: LIGHT_COLOR.SPLASH,
        green: LIGHT_COLOR.GREEN,
        red: LIGHT_COLOR.RED,
        pink: LIGHT_COLOR.PINK,
        pink_light: LIGHT_COLOR.PINK_LIGHT,
        yellow: LIGHT_COLOR.YELLOW,
        border_gray: LIGHT_COLOR.BORDER_GRAY,
        orange_lv_max: LIGHT_COLOR.ORANGE_LV_MAX,
    },
};
exports.PaperThemeDark = {
    ...react_native_paper_1.DarkTheme,
    colors: {
        ...react_native_paper_1.DarkTheme.colors,
        ...native_1.DarkTheme.colors,
        primary: DARK_COLOR.PURPLE_700,
        accent: DARK_COLOR.BLUE_600,
        shadow: DARK_COLOR.GRAY_600,
        text: DARK_COLOR.WHITE,
        background: DARK_COLOR.BLACK_900,
        gray_400: DARK_COLOR.GRAY_400,
        gray_500: DARK_COLOR.GRAY_500,
        gray_700: DARK_COLOR.GRAY_700,
        gray_100: DARK_COLOR.GRAY_100,
        blue_200: DARK_COLOR.BLUE_200,
        blue_100: DARK_COLOR.BLUE_100,
        white: DARK_COLOR.WHITE,
        surface: DARK_COLOR.BLACK_900,
        purple_100: DARK_COLOR.PURPLE_100,
        purple_600: DARK_COLOR.PURPLE_600,
        backdrop: DARK_COLOR.BACKDROP,
        textinput_bg: DARK_COLOR.TEXTINPUT_BG,
        kakaotalk: DARK_COLOR.KAKAOTALK,
        facebook: DARK_COLOR.FACEBOOK,
        blue_20: DARK_COLOR.BLUE_20,
        transparent: DARK_COLOR.TRANSPARENT,
        background_login: DARK_COLOR.BACKGROUND_LOGIN,
        black: DARK_COLOR.BLACK,
        placeholder: DARK_COLOR.PLACE_HOLDER,
        orange: DARK_COLOR.ORANGE,
        violet_light: DARK_COLOR.VIOLET_LIGHT,
        violet_ba: DARK_COLOR.VIOLET_BA,
        gray: DARK_COLOR.GRAY,
        text_content: DARK_COLOR.TEXT_CONTENT,
        text_content_gray: DARK_COLOR.TEXT_CONTENT_GRAY,
        tab_gray: DARK_COLOR.TAB_GRAY,
        layout: DARK_COLOR.LAYOUT,
        gray_300: DARK_COLOR.GRAY_300,
        gray_ff57: DARK_COLOR.GRAY_FF57,
        gray_444: DARK_COLOR.GRAY_444,
        rgb_black_light: DARK_COLOR.RGB_BLACK_LIGHT,
        blue: DARK_COLOR.BLUE,
        splash: DARK_COLOR.SPLASH,
        green: DARK_COLOR.GREEN,
        red: DARK_COLOR.RED,
        pink: DARK_COLOR.PINK,
        pink_light: DARK_COLOR.PINK_LIGHT,
        yellow: DARK_COLOR.YELLOW,
        border_gray: DARK_COLOR.BORDER_GRAY,
        background_layout: DARK_COLOR.BACKGROUND_LAYOUT,
        orange_lv_max: DARK_COLOR.ORANGE_LV_MAX,
    },
};
exports.CombinedDefaultTheme = {
    ...react_native_paper_1.DefaultTheme,
    ...native_1.DefaultTheme,
};
exports.CombinedDarkTheme = {
    ...react_native_paper_1.DarkTheme,
    ...native_1.DarkTheme,
    colors: {
        ...react_native_paper_1.DarkTheme.colors,
        ...native_1.DarkTheme.colors,
    },
};
