"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Linking = void 0;
exports.Linking = {
    prefixes: [
        'https://eyeber.io',
        'http://localhost:8001',
        'eyeber://'
    ],
    config: {
        screens: {
            AuthScreen: {
                path: 'auth',
                screens: {}
            },
            TabStack: {
                path: '',
                screens: {
                    'coin': {
                        path: 'coin',
                        screens: {},
                    },
                    'shop': {
                        path: 'shop',
                        screens: {},
                    },
                    'inventory': {
                        path: 'inventory',
                        screens: {},
                    },
                },
            },
            NotFound: '*',
        },
    },
};
