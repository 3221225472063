"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumProductInventory = exports.enumListShop = void 0;
var enumListShop;
(function (enumListShop) {
    enumListShop[enumListShop["repair"] = 1] = "repair";
    enumListShop[enumListShop["glass"] = 0] = "glass";
})(enumListShop = exports.enumListShop || (exports.enumListShop = {}));
var enumProductInventory;
(function (enumProductInventory) {
    enumProductInventory[enumProductInventory["can"] = 1] = "can";
    enumProductInventory[enumProductInventory["canNot"] = 0] = "canNot";
})(enumProductInventory = exports.enumProductInventory || (exports.enumProductInventory = {}));
