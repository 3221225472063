"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINK_IMAGE_DEFAULT = exports.GOOGLE = exports.FACEBOOK = exports.EMAIL_SUPPORT = exports.MAX_LIMIT = exports.NCP_CONTENT = exports.NCP_FROM = exports.NCP_COUNTRY_CODE = exports.NCP_SECRET_KEY = exports.NCP_KEY = exports.SERVICE_ID = exports.FALSE = exports.TRUE = exports.SUCCESS = exports.USER_CODE = exports.API_KEY = exports.nativeDriverConfig = exports.durationMs = exports.openedPercent = exports.WEB_CLIENT_ID = void 0;
exports.WEB_CLIENT_ID = '57383360009-cg61jn3btahekqf5pjrsn9hghv64j3fd.apps.googleusercontent.com';
exports.openedPercent = 100;
exports.durationMs = 350;
exports.nativeDriverConfig = {
    useNativeDriver: true,
};
exports.API_KEY = {
    TWITTER_API_KEY: 'PgqZTTfPTm7afm5oXeN3f63Ig',
    TWITTER_SECRET_KEY: 'lvpFT8iIjAyANP4sQi9zaFaA3eBHZ611MoL9gUM5I7k0vsKmKR',
    TWITTER_BEARER_TOKEN: 'AAAAAAAAAAAAAAAAAAAAAEkAfgEAAAAAY%2B9rMGFLguNdPlisqDvA8GN%2Fi2A%3DaFtYkhJh4HakQ9FXQLSon9uHXJTa5T82VdbxbhASmOfIl5T1C4'
};
exports.USER_CODE = 'imp38614078';
exports.SUCCESS = 'success';
exports.TRUE = 'TRUE';
exports.FALSE = 'FALSE';
exports.SERVICE_ID = "ncp:sms:kr:258470086314:aucshow";
exports.NCP_KEY = "0M4j7bdpbppTWmxhsifB";
exports.NCP_SECRET_KEY = "vuVk61Z8UgIwPYZuAxCCN34dfQ1BO1IZrmkQZXRS";
exports.NCP_COUNTRY_CODE = "82";
exports.NCP_FROM = "16611126";
exports.NCP_CONTENT = "OTP:";
exports.MAX_LIMIT = 1000000000;
exports.EMAIL_SUPPORT = 'eyeber.info@gmail.com';
exports.FACEBOOK = 'facebook';
exports.GOOGLE = 'google';
exports.LINK_IMAGE_DEFAULT = 'https://i.postimg.cc/Bv2nscWb/icon-default-avatar.png';
