"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enumStatus = exports.enumSwap = void 0;
var enumSwap;
(function (enumSwap) {
    enumSwap[enumSwap["can"] = 1] = "can";
    enumSwap[enumSwap["not_can"] = 0] = "not_can";
})(enumSwap = exports.enumSwap || (exports.enumSwap = {}));
var enumStatus;
(function (enumStatus) {
    enumStatus["all"] = "";
    enumStatus[enumStatus["deposit"] = 0] = "deposit";
    enumStatus[enumStatus["withdrawal"] = 1] = "withdrawal";
})(enumStatus = exports.enumStatus || (exports.enumStatus = {}));
