"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkNumberAndDot = exports.checkNumber = void 0;
const checkNumber = (text) => {
    let newText = '';
    let numbers = '0123456789';
    for (var i = 0; i < text?.length; i++) {
        if (numbers.indexOf(text[i]) > -1) {
            newText = newText + text[i];
        }
    }
    return newText;
};
exports.checkNumber = checkNumber;
const checkNumberAndDot = (text) => {
    let newText = '';
    let numbers = '0123456789.';
    let isCheckDot = 0;
    for (var i = 0; i < text?.length; i++) {
        if (numbers.indexOf(text[i]) > -1) {
            if (isCheckDot == 1 && text[i] != '.' || isCheckDot == 0) {
                newText = newText + text[i];
            }
        }
        if (text[i] == '.') {
            isCheckDot = 1;
        }
    }
    return newText;
};
exports.checkNumberAndDot = checkNumberAndDot;
